.input-pet-medical{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
}

.input-pet-medical-dropdown{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    width: 200px;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
}

.input-pet-detail-notes{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
    width:80%;
}

.input-pet-detail-nextdate{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
    width:70%;
}

.input-pet-details-reports{
    border: none;
    outline: none;
    box-shadow: none;
    outline-width: 0;
    font-size: 14px;
}

/* Media Queries */
/* 
@media only screen and (max-width:926px)
{
    .btn-add-record
    {
        width: 75% !important;
    }

    .main_div_add_new_record
    {
        width: 100% !important;
        padding: 25px !important;
    }
    
}

@media only screen and (max-width:800px)
{

    .outer-med-add-record-div
    {
        width: 100% !important;
        
    }

    .inner-set-rem-div
    {
        width: 100% !important;
    }
    .input-pet-detail-notes
    {
        width:80% !important;
    }

    .inner-set-rem-div .pet-detail-notes
    {
        margin-left: 0px !important;
    }


    .set-rem-div label-petdata
    {
        text-align: left !important;
        margin-left: 20px !important;
    }
    .inner-set-rem-div
    {
        width: 95% !important;
    }
    
}

@media only screen and (max-width:770px)
{
    .inner-set-rem-div p
    {
              
              width: fit-content !important;
              
    }
    .reminder_title
    {
        width: 100% !important;
    }
    
}



@media only screen and (max-width:600px)
{
    .main_div_add_new_record
    {
        width: 400px !important;
        padding: 18px !important;
    }
    
}

@media only screen and (max-width:500px)
{


    .main_div_add_new_record
    {
        width: 360px !important;
        padding: 14px !important;
    }

    .major-btn-div
    {
        flex-direction: column !important;
    }
    
    
}

@media only screen and (max-width:400px)
{

    .input-pet-detail-notes
    {
        width: 250px !important;
    }
    
}


@media only screen and (max-width:443px) 
{
    .main_div_add_new_record
    {
        width: fit-content !important;
        
    }

    .outer-med-add-record-div
    {
        width: fit-content !important;
    }

    .set-rem-div
    {
        width: fit-content !important;
    }

    .input-pet-detail-notes
    {
        width: 70% !important;
    }
    
}

@media only screen and (max-width:420px)
{

    .main_div_add_new_record
    {
        width: fit-content !important;
        padding: 14px !important;
    }

    .major-btn-div
    {
        flex-direction: column !important;
        width: 80% !important;
    }

    .btn-add-record
    {
        width: 100% !important;
    }

    .first-line
    {
        flex-direction: row !important;
    }

     

     .inputs__notes
     {
         width: 75% !important;
     }

    
}

@media only screen and (max-width:443px) and (min-width:365px)
{
    .exp-btn
    {
        position: absolute !important;
        top: 60px !important;
        right: 30px !important;
    }

    
}

@media only screen and (max-width:365px)
{

     .inner-set-rem-div, .set-rem-div
    {
        width: 100% !important;
    } 

    .outer-med-add-record-div
    {
        width: fit-content !important;
    }
     .set-rem-div
    {
        margin: 0px !important;
        width: fit-content !important;

    }
    .outer-med-add-record-div 
    {
            margin-left: -5px !important;
    }
    .exp-btn
    {
        position: absolute !important;
        top: 60px !important;
        right: 30px !important;
    }
    
}

@media only screen and (max-width:290px)
{
    .outer-med-add-record-div , .select-type-div
    {
        margin-left: -15px !important;
    }

    .major-btn-div
    {
        width: 100% !important;
    }
    
} */

/* Media Queries From Update */

@media only screen and (max-width:770px)
{

    .inner-med-rem-div p
    {
              
      width: fit-content !important;
              
    }
    
}



@media only screen and (max-width:555px)
{
    .upper-form-update
    {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .outer-med-add-record-div
    {
        padding-right: 15px !important;
    }
    
}

@media only screen and (max-width:540px)
{
    .input-pet-medical, .input-pet-medical-dropdown
    {
        width: 160px !important;
    }
    
}

@media only screen and (max-width:453px)
{
    .first-line-update div, .sever
    {
        margin-top: 10px !important;
    }
    
}

@media only screen and (max-width:443px)
{
    .outer-med-add-record-div
    {
        width: fit-content !important;
        padding-right: 5px !important;
    }
    .upper-form-update
    {
           
            width: 100% !important;
            padding-right: 0px !important;
    }

    .txt_notes
    {
        width: 95% !important;
    }

    .input-pet-detail-notes , .input-pet-detail-nextdate 
    {
        width: 80% !important;
    }

    
    
    
}

@media only screen and (max-width:414px)
{
    .outer-med-add-record-div
    {
        width:100% !important;
        padding-right: 5px !important;
    }
    .upper-form-update
    {
        width: 100% !important;
    }
    
}

@media only screen and (max-width:367px)
{

    .sak-rem-div
    {
        width: 100% !important;
    }
    .outer-med-add-record-div
    {
        padding-left: auto !important;
    }

    .upper-form-update
    {
        margin-left: auto !important;
    }
    
}


@media only screen and (max-width:330px)
{
    .btn-expand
    {
        margin-left: 40px !important;
    }
    
}

@media only screen and (max-width:300px) and (min-width:270px)
{
    .btn-expand
    {
        margin-left: 35px !important;
    }
    
}
/* My New Media Queries */

@media only screen and (max-width:610px)
{
    .outer-med-add-record-div
    {
        width: 500px !important;

    }
}

@media only screen and (max-width:573px)
{
    .outer-med-add-record-div
    {
        width: 480px !important;

    }

    
}

@media only screen and (max-width:539px)
{
    .outer-med-add-record-div
    {
        width: 440px !important;

    }

    
}

@media only screen and (max-width:500px)
{
    .outer-med-add-record-div
    {
        width: 410px !important;

    }

    
}

@media only screen and (max-width:477px)
{
    .outer-med-add-record-div
    {
        width: 380px !important;

    }

    .sever
    {
        margin-top: 10px !important;
    }

    
}
@media only screen and (max-width:444px)
{
    .outer-med-add-record-div
    {
        width: 350px !important;

    }

    
}
@media only screen and (max-width:418px)
{
    .outer-med-add-record-div
    {
        width: 310px !important;

    }

    

    
}

@media only screen and (max-width:365px)
{
    .btn-expand
    {
        margin-left: 60px !important;
    }
    
}

@media only screen and (max-width:364px)
{
    .outer-med-add-record-div
    {
        width: 290px !important;

    }

    
}

@media only screen and (max-width:341px)
{
    .btn-expand
    {
        margin-left: 120px !important;
        margin-top: -25px !important;
    }
    
}

@media only screen and (max-width:417px) and (min-width:365px)
{
    .rem-cal-para
    {
        text-align: left !important;
    }
    
}



@media only screen and (max-width:340px)
{
    .outer-med-add-record-div
    {
        width: 250px !important;

    }

    
}

@media only screen and (max-width:330px)
{
    .btn-expand
    {
        margin-left: 40px !important;
    }
    
}

@media only screen and (max-width:300px)
{
    .outer-med-add-record-div
    {
        width: 240px !important;

    }

    .mq-remdiv
    {
        margin-left: -5px !important;
    }

    .mq-add-rec
    {
        margin-left: -10px !important;
        /* width: 80px !important; */
    }

    
}




@media only screen and (max-width:300px) and (min-width:270px)
{
    .btn-expand
    {
        margin-left: 35px !important;
    }
    
}



@media only screen and (max-width:288px)
{
    .outer-med-add-record-div
    {
        width: fit-content !important;
        padding-right: 8px !important;

    }
    .major-btn-div
    {
        flex-direction: column !important;
    }

    
}
/* myexp-btn media Queries */

@media only screen and (max-width:522px)
{
    .myexp-btn
    {
        margin-left: 80px !important;
        
    }
    
}

@media only screen and (max-width:445px) 
{
    .medicon-exp-btn
    {
        margin-left: 185px !important;
        margin-top: -16px !important;
    }
    
}


@media only screen and (max-width:426px)
{
    .medicon-exp-btn
    {
        margin-left: 170px !important;
        margin-top: -25px !important;
    }
    
}




@media only screen and (max-width:418px)
{
   

    .myexp-btn
    {
        margin-top: -5px !important;
        margin-left: 70px !important;
    }

    .medicon-exp-btn
    {   margin-left: 185px !important;     
        margin-top: -30px !important;
    }
    
}

@media only screen and (max-width:365px)
{
    .medicon-exp-btn
    {
        margin-left: 115px !important;
        margin-top: -20px !important;
    }

    .myexp-btn
    {
        margin-top: -15px !important;
        margin-left: 135px !important;
    }
    
}

@media only screen and (max-width:340px)
{
    .myexp-btn{
        margin-left: 115px !important;
        margin-top: -20px !important;
    }
    .measure-exp-btn
    {
        margin-left: 85px !important;
    }
    
}


@media only screen and (max-width:290px)
{
    .med-rem-div
    {
        margin-left: -8px !important;
        margin-right: 0px !important;
    }

    .upper-form-update
    {
        margin-left: -10px !important;
    }

    .select-type-div
    {
        margin-left: -10px !important;
    }
}

@media only screen and (max-width:288px)
{
    .mq-set-rem-div-medcon
    {
        margin-left: -10px !important;
    }
    .mq-med-con-add-record
    {
          width: 235px !important;
    }
    
}

@media only screen and (max-width:284px)
{
    .mq-med-con-add-record

    {
        width: 230px !important;
    }
}

/* @media only screen and (max-width:290px)
{

   .upper-form-update
   {
       width: 90% !important;
       margin-left: -8px !important;
   }
}

@media only screen and (max-width:288px)
{
    .upper-form-update
    {
        width: 80% !important;
        margin-left: -8px !important;
    }
    
} */
