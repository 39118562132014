

/* Yashvi key css start */
.petdetails-height{
    min-height: 800px !important;
}

.background{
    min-height: 100% !important;
    height: 100%;
}

.ngx-timepicker-field-example{
    height: 30px !important;
    font-size: 25px !important;
}

.lostincipet{
    text-transform: none !important;
}

.mat-button-toggle{
    width: 200px;
    border: none;
    outline: none;
    word-wrap: break-word;
}

.mat-button-toggle-group{
    border: none;
    outline: none;
}

.mat-button-toggle-group:focus .mat-button-toggle-group:checked{
    outline: none !important;
    border: none !important;
}

.mat-button-toggle:focus .mat-button-toggle:checked{
    outline: none !important;
    border: none !important;
}

.mat-button-toggle-button:focus .mat-button-toggle-button:checked{
    outline: none !important;
    border: none !important;
  }

.gray-color {
    color:lightgray;
}

.bg-gray{
    background-color: lightgrey;
}

.profile-sidenav{
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 400px;
}

.p-info{
    width: 85%;
    border-radius: 5px;
}

.lightgray-color{
    background: #e9eaed;
}

.fa-user-circle-o{
        background: #ebebeb;
        color: rgb(211, 209, 209);
        border-radius: 50%;
        border: none;
        outline: none;
        margin-top: -10px;
}

.full-name{
    font-size: 22px;
    font-weight: 350px;
    margin-top: -10px;
}

.full-name-1{
    font-size: 17px;
    font-weight: 350px;
}

.full-name-2{
    font-size: 17px;
    font-weight: 350px;
}

.fullname-email{
    font-size: 17px;
    font-weight: 350px;
    margin-top: -23px;
}

.full-nickname{
    margin-top: 50px;
}

.nickname{
    margin-left: 40%;
}

.nickname-1{
    margin-left: 20%;
}

.h-100{
    height: 100%;
}

.edit-button{
    margin-left: 35%;
    color: rgb(211, 209, 209);
    border: none;
    outline: none;
    background-color: white;
}

.mobile-update{
    width: 250px;
}

.googlemaps {
    width: 60% !important;
    height: 300px !important;
}

.latlong{
    padding-top: 290px;
}

.loss-details-header{
    width: 68%;
    color: white;
    margin-left: 15%;
    border: 1px solid black;
}
.loss-details-body{
    width: 68% !important;
    margin-left: 15%;
    border: 1px solid black;
    word-wrap: break-word;
    text-overflow: clip;
}
.know-where-lost{
    font-size:16px;
}

.fa-map-marker-alt{
    margin-top: -5px;
    font-size: 18px;
    color: gray;
}

.fa-calendar-alt{
    margin-top: -5px;
    font-size: 18px;
    color: gray;
}

.fa-clock{
    margin-top: -5px;
    font-size: 18px;
    color: gray;
}


.reward-header{
    letter-spacing: 1px;
}

.reward-body{
    font-size: 17px;
}

.input-pet-details{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 200px;
    height: 20px;
    font-size: 14px;
}

.input-pet-details-1{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 440px;
    height: 20px;
    font-size: 14px;
}

.input-pet-details-2{
  border: none;
  outline: none;
  border-bottom: 2px solid #a1a1a1;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
  background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown{
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  width: 200px;
  height: 20px;
  box-shadow: none;
  font-size: 14px;
  padding: 0px;
}

.label-petdata{
    font-size: 12px;
    color: #b4b4b4;
}

.label-petdata-1{
  font-size: 12px;
  color: #777777;
}

button:focus{
    border:none;
    outline: none;
    box-shadow: none;
    background-color: white;
}

button:hover{
    border:none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

#lost-calendar-preview{
    width: 250px;
    margin-left: 35%;
}

.text-gray{
    color:  #b4b4b4;
}

.inline-display{
    display: inline-block;
}

.lost-time{
    margin: auto;
    width: 200px;
}

input{
    padding: 0px !important;
  }

/* @media only screen and (max-width:1310px){
    .mat-button-toggle{
        width: 150px;
    }
} */

.petparentlinks-rl{
    margin-left: -30px !important;
}

@media only screen and (max-width:960px){
    /* .mat-button-toggle{
        width: 100px;
    } */
    .mat-button-toggle-group {
        flex-wrap: wrap;
        margin: auto !important;
      }
    .googlemaps {
        width: 60% !important;
        height: 200px !important;
    }

    .latlong{
        padding-top: 200px;
    }
    .loss-details-body{
        margin-top: 100px !important;
    }
    .dateandtime{
        margin-top: 20px !important;
    }
}

@media only screen and (max-width:850px){
    .mat-toggle-group-original{
        margin-top: -20px !important;
    }
    .mat-button-toggle{
        width: 150px !important;
        font-size: 14px !important;
    }
    .rightbackground{
        min-height: 1000px !important;
        height: 1000px !important;
    }
}

@media only screen and (max-width:804px){
    .angularlocationmap{
        height: 200px !important;
    }
}


@media only screen and (max-width:800px){
    #lost-calendar-preview{
        margin-left: 25%;
    }
    .mat-button-toggle{
        width: 120px !important;
        font-size: 14px !important;
    }
}

@media only screen and (max-width:747px){
    .marginheight-rl{
        margin-top: 0px !important;
    }
}

@media only screen and (max-width:700px){
    .leftside-buttons-rl{
        font-size: 16px !important;
    }
}

@media only screen and (max-width:650px){
    #lost-calendar-preview{
        margin-left: 15%;
    }

    .reportlostheight{
        height: 100% !important;
    }
    
    .mat-toggle-group-original{
        margin-top: 00px !important;
    }

}

@media only screen and (max-width:576px){
    #lost-calendar-preview{
        margin-left: 20%;
    }
    .marginheight-rl{
        margin-top: 15px !important;
    }
    .uppercontainer{
        margin-left: 8px !important;
        width: 100% !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:480px){

      /* .mat-button-toggle{
        width: 50% !important;
      } */
      
    .loss-details-body{
        width: 90%;
        margin: auto;
    }
    .nextback{
        height: 35px !important;
        width: 200px !important;
    }
    .uppercontainer{
        width: 95% !important;
        margin-right: -10px !important;
    }
}

@media only screen and (max-width:450px){
    #lost-calendar-preview{
        margin-left: 15%;
    }

    .mat-raised-button{
        width: 200px !important;
    }
}

@media only screen and (max-width:415px){
    .mat-button-toggle{
        width:160px !important;
        font-size: 14px !important;
        height: 40px !important;
    }
}

@media only screen and (max-width:400px){
    #lost-calendar-preview{
        margin-left: 10%;
    }

    .lost-time{
        width: 100%;
    }

    .ngx-timepicker-field-example-col{
        margin-left: -50px !important;
    }
}

@media only screen and (max-width:380px){
    .timerangeofloss{
        margin-top: -30px !important;
    }
    .googlemaps {
        width: 60% !important;
        height: 130px !important;
    }
    .latlong{
        padding-top: 130px;
    }
    .reward_div{
        margin-left: -40px !important;
    }
    .set-time{
        font-size: 22px !important;
    }
}

@media only screen and (max-width:350px){
    #lost-calendar-preview{
        margin-left: 15%;
        width: 200px;
    }

    .inline-display-1{
        display: inline-block;
    }

    .rewardcss{
        width: 200px !important;
    }

    .set-time{
        font-size: 20px !important;
    }
}


@media only screen and (max-width:312px){

    #lost-calendar-preview{
        margin-left: 10%;
        width: 200px;
    }

    .lost-time{
        width: 100%;
    }
    .ngx-timepicker-field-example-col{
        margin-left: -60px !important;
    }

    .reward_div{
        margin-left: -55px !important;
    }

    
    .set-time{
        font-size: 16px !important;
    }
}

@media only screen and (max-width:290px){
    #lost-calendar-preview{
        margin-left: 8%;
    }

    .mat-raised-button{
        width: 150px !important;
    }

    .ngx-timepicker-field-example-col{
        margin-left: -65px !important;
    }

    .reward_div{
        margin-left: -50px !important;
    }

    .reward_div input, .reward_div select{
        width: 180px !important;
    }

    .set-time{
        font-size: 13px !important;
        margin-left: -35px !important ;
    }
}


/* ----------------------------- */

.profile__leftPanel{
    display: flex;
    height: 100vh;
    flex-direction: column;
    padding: 20px;
    margin: 30px 1px;
    margin-left: 0;
    flex:0.25;
    background-color: #343a40;
    color: white;
}
.fa-user-circle{
    font-size: 100px;
    color: white;
    margin-bottom: 5px;
}
.profile__button{
    color: white;
    text-decoration: none;
    align-self: baseline;
    margin-left: 0;
    font-size: 14px !important;
}
.profile__button:hover{
    text-decoration: none;
    color: white;
    background-color: #32383e;
}
.profile__subButtons{
    color: white;
    align-self: baseline;
    margin: 0 20px;
    font-size: 14px;
    padding: 10px;
}
.profile__subButtons:hover{
    
    text-decoration: none;
    color: white;
    background-color: #32383e;
}
