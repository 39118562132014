.input-pet-medical{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    outline-width: 0;
    width: 120px;
    height: 20px;
    font-size: 14px;
}

.input-pet-medical-dropdown{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    width: 120px;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
}

.input-pet-detail-notes{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
    width:100%;
}

.input-pet-detail-nextdate{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
    width:70%;
}

.input-pet-details-reports{
    border: none;
    outline: none;
    box-shadow: none;
    outline-width: 0;
    font-size: 14px;
}

.med-rem-div
{
    width: 100% !important;
}

.inner-med-rem-div
{
    /* width: 100% !important; */
    width: fit-content !important;
    padding-right: 0px !important;
}


/* Media Queries */

@media only screen and (max-width:770px)
{

    .inner-med-rem-div p
    {
              
      width: fit-content !important;
              
    }
    
}



@media only screen and (max-width:555px)
{
    .upper-form-update
    {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .outer-med-add-record-div
    {
        padding-right: 15px !important;
    }
    
}

@media only screen and (max-width:540px)
{
    .input-pet-medical, .input-pet-medical-dropdown
    {
        width: 160px !important;
    }
    
}

@media only screen and (max-width:453px)
{
    .first-line-update div, .sever
    {
        margin-top: 10px !important;
    }
    
}

@media only screen and (max-width:443px)
{
    .outer-med-add-record-div
    {
        width: fit-content !important;
        padding-right: 5px !important;
    }
    .upper-form-update
    {
            width: 100% !important;
            padding-right: 0px !important;
    }

    .txt_notes
    {
        width: 95% !important;
    }

    .input-pet-detail-notes , .input-pet-detail-nextdate 
    {
        width: 80% !important;
    }
    
}

@media only screen and (max-width:417px) and (min-width:365px)
{
    .rem-cal-para
    {
        text-align: left !important;
    }
    
}


@media only screen and (max-width:414px)
{
    .outer-med-add-record-div
    {
        width:100% !important;
        padding-right: 5px !important;
    }
    .upper-form-update
    {
        width: 100% !important;
    }
    
}

@media only screen and (max-width:367px)
{

    .sak-rem-div
    {
        width: 100% !important;
    }
    .outer-med-add-record-div
    {
        padding-left: auto !important;
    }

    .upper-form-update
    {
        margin-left: auto !important;
    }
    
}

@media only screen and (max-width:365px)
{
    .btn-expand
    {
        margin-left: 60px !important;
    }
    
}

@media only screen and (max-width:341px)
{
    .btn-expand
    {
        margin-left: 120px !important;
        margin-top: -25px !important;
    }
    
}

@media only screen and (max-width:330px)
{
    .btn-expand
    {
        /* margin-left: 60px !important; */
        margin-top: -5px !important;
        margin-right: -80px !important;
    }
    
}


@media only screen and (max-width:330px)
{
    .btn-expand
    {
        margin-left: 40px !important;
    }
    
}

@media only screen and (max-width:290px)
{
    .upper-form-update
    {
        margin-left: 30px !important;
    }
    
}

@media only screen and (max-width:300px) and (min-width:270px)
{
    .btn-expand
    {
        margin-left: 35px !important;
    }
    
}


/* myexp-btn media Queries */

@media only screen and (max-width:522px)
{
    .myexp-btn
    {
        margin-left: 30px !important;
        
    }
    
}
@media only screen and (max-width:418px)
{
    .medicon-exp-btn
    {
        margin-right: -15px !important;
        margin-top: -10px !important;
    }

    .measure-update-btn
    {
        margin-top: -5px !important;
        margin-left: 60px !important;
    }

    .med-con-update-btn
    {
        margin-left: 190px !important;
        margin-top: -15px !important;
    }
    
}


@media only screen and (max-width:367px)
{
    .rem-cal-para
    {
        text-align: left !important;
    }
    
}

@media only screen and (max-width:365px)
{
    .medicon-exp-btn
    {
        margin-left: 115px !important;
        margin-top: -20px !important;
    }

    .measure-update-btn
    {
        margin-top: -20px !important;
        margin-left: 120px !important;
    }

    .med-con-update-btn
    {
         margin-right: -10px !important;
         margin-left: 150px !important;
        margin-top: -20px !important;
    }
    
}

@media only screen and (max-width:340px)
{
    .myexp-btn{
        margin-left: 75px !important;
        margin-top: -20px !important;
    }
    
    /* .measure-exp-btn
    {
        
        margin-top: 20px !important;
        margin-left: 310px !important;
    } */

    .inner-med-rem-div p
    {
        margin-right: 20px !important;
    }

    /* .measure-2
    {
        margin-left: 260px !important;
    } */
    
}
