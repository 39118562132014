.navbar__options{
  /* font-weight: bold; */
  color: white !important;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 18px !important;
  text-decoration: none !important;
}

.dropbtn {
  font-size: 18px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  margin-left: -35px;
  margin-top: 0px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  margin-top: 0px;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-1 > a {
  width: inherit;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content-1 a:hover {
  background-color: black;
  color: white;
  text-decoration: none;
}

.dropdown:hover .dropdown-content-1 {
  display: block;
}

.dropdown-content > a {
  width: inherit;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: black;
  color: white;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: inherit;
}

.got-genie1 {
  background-color: #e5bd55 !important;
  height: 55%;
  padding: 10px;
  margin-right: 1rem;
  font-family: "Lato" sans-serif;
  border-radius: 10px;
  width: 165px;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #a78107;
  border-radius: 10px;
  margin-right: 1rem;
}

.got-genie-top {
  color: #0d4e91;
}

.got-genie-div {
  display: flex;
  margin-left: auto;
  align-items: center;
  overflow: hidden;
  float: right;
  outline: none;
}

.got-genie-div-1 {
  display: none;
  margin-left: auto;
  align-items: center;
  overflow: hidden;
  float: left !important;
  outline: none;
}

.got-genie-form{
  margin: 5px;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  background-color: #24292d;
  border: 1px solid #504d4d;
  border-radius: 10px;
}

.got-genie-form > input {
  width: 70%;
  height: 30px;
  align-self: center;
  text-align: center;
  border-radius:5px ;
  margin-top: 10px;
}

.genie-form-buttons {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}

.genie-form-buttons > button {
  background-color: white;
  padding: 5px 13px;
  margin: 10px 8px;
  margin-top: 12px;
  /* border: none; */
  border-radius: 5px;
}

.no-hover:hover {
  background-color: white;
}

.dropdown-item:hover {
  background-color: black !important;
  color: white !important;
}

.genie-form-buttons> button:hover{
  background-color: aliceblue;
  cursor: pointer;
}

.genie-form-buttons > button:hover:disabled {
  cursor: inherit;
}

.genie-form-buttons > button:disabled {
  background-color: #d3d3d3;
}

.show-on-responsive {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .got-genie-div {
    float: left !important;
    display: none !important;
  }
  .got-genie-div-1 {
    display: flex !important;
    margin-top: 15px !important;
  }
  .navbar__options {
    margin-top: 15px !important;
  }
  .topmargin-responsive {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 550px) {
  .dialog_profile__change__pwd {
    width: fit-content !important;
  }
}

@media only screen and (max-width: 520px) {
  .profile__change__pwd {
    padding: 8px !important;
  }
}

@media only screen and (max-width: 480px) {
  .profile__change__pwd {
    width: 380px !important;
  }

  .change__pwd_pwd,
  .change__pwd_email {
    width: 320px !important;
  }
}

@media only screen and (max-width: 443px) {
  .profile__change__pwd {
    width: 300px !important;
  }

  .change__pwd_pwd,
  .change__pwd_email {
    width: 85% !important;
  }
}

@media only screen and (max-width: 410px) {
  .got-genie1 {
    min-height: 55% !important;
    padding: 5px !important;
    width: 125px !important;
  }

  .activetag__PopUp {
    width: 250px !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  .activetag__PopUp h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 360px) {
  .act__dialog {
    padding: 0px !important;
    width: fit-content !important;
  }
  .activetag__PopUp {
    width: 280px !important;
    padding: 0px !important;
  }

  .activetag__PopUp h1 {
    text-align: center !important;
  }
}

@media only screen and (max-width: 370px) {
  .got-genie1 {
    min-height: 55% !important;
    padding: 5px !important;
    width: 125px !important;
    margin-top: -120px !important;
  }
  .got-genie-form {
    display: flex !important;
    margin-left: -125px !important;
    padding: 10px;
    margin-top: 75px !important;
  }

  .act__dialog {
    width: fit-content !important;
  }

  .activetag__PopUp {
    width: 280px !important;
  }
}

@media only screen and (max-width: 345px) {
  .act__dialog {
    padding: 5px !important;
  }
}

@media only screen and (max-width: 358px) {
  .profile__change__pwd {
    width: 280px !important;
  }

  .verifyOtp-button,
  .otp-buttons {
    width: 60% !important;
    margin-left: 50px !important;
  }
}
@media only screen and (max-width: 358px) {
  .activetag__PopUp {
    width: 280px !important;
  }
}

@media only screen and (max-width: 340px) {
  .profile__change__pwd {
    padding: 2px !important;
  }
}

@media only screen and (max-width: 337px) {
  .profile__change__pwd {
    width: 260px !important;
  }
}

@media only screen and (max-width: 315px) {
  .profile__change__pwd {
    width: 240px !important;
  }
}

@media only screen and (max-width: 305px) {
  .activetag__PopUp {
    width: 220px !important;
  }
}

@media only screen and (max-width: 289px) {
  .profile__change__pwd {
    width: 230px !important;
  }
}
