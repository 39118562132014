.legalterms{
    padding: 50px 250px;
}
.legalterms > h1{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    margin-top: 50px;
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #ffc107 ;
    text-align: center;
    margin-bottom: 2rem !important;
}
.legalterms > p{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    font-size: 16px !important;
    color: gray;
    letter-spacing: 1px;
    line-height: 27px;
    text-align: justify;
    margin-top: 1rem !important;    
    margin-right: -15px;
    margin-left: -15px;

}

/* Media Queries */

@media only screen and (max-width:1024px) and (min-width:768px) {
    .legalterms p{
        width:  100% !important;
    }
    .legalterms{
        padding: 25px 40px !important;
    }
    .legalterms h1{
        font-size: 28px !important;
        line-height: 35px !important;
    }
}

@media only screen and (max-width:768px){

    .legalterms p{
        width:  100% !important;
        margin-left: 5px !important;
    }
    .legalterms{
        padding-left: 40px !important;
        padding-right: 40px !important;

        padding-top: 25px !important;
        padding-bottom: 25px !important;

    }
    .legalterms h1{
        font-size: 28px !important;
        line-height: 35px !important;
    }
    
}