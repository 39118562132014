.body-login{
    width: 85%;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
    margin-top: 15px;
}

.background{
    height: 100vh;
}

.image-upload{
width: 120px;
height: 120px;
margin: auto !important;

}

select{
    border: none;
    outline: none;
    border-bottom: 2px solid #e4e4e4;
    width: 200px;
    height: 20px;
    box-shadow: none;
    font-size: 13px;
    padding: 0px;
  }

#choose-profile-imageActi{
    display: none;
}

#choose-profile-imageActi-2{
  display: none;
}

input{
padding: 0px !important;
}

select{
padding: 0px !important;
}

.mat-raised-button:disabled{
background-color:#FCBC1B;
}

.image{
height: 120px;
width: 120px;
border-radius: 50%;
text-align: center;
}

.overlay{
height: 120px;
width: 120px;
background-color: black;
margin-top: -120px;
position: absolute;
opacity:0;
border-radius: 50%;
}
.button-image{
background-color: transparent;
border: none;
outline: none;
}
.fa-edit{
margin-top:48px;
color: white;
}

.plus-icon{
margin-top: 0px;
color: #a1a1a1;
}

.image-upload:hover .overlay{
background-color: rgba(0,0,0,0.5);
opacity:1;
}

.pd-leftside-navigation{
  list-style: none; 
  margin: auto; 
  margin-left: -30px;
}

#uploadPreviewActi{
width: 120px;
height: 120px;
margin-top: -148px !important;
border-radius: 50%;
border:none;
outline:none;
}

#uploadPreview{
width: 120px;
height: 120px;
margin-top: -148px !important;
border-radius: 50%;
display: none;
border:none;
outline:none;
}


.left-side{
border-radius: 15px 0 0 15px;
background-color: rgb(255, 234, 177);
}

.row-underline{
border-bottom: 5px solid #FCBC1B;
}

textarea:focus{
outline: none !important;
box-shadow: none !important;
border: 2px solid #e4e4e4 !important;
}

.input-pet-details{
border: none;
outline: none !important;
border-bottom: 2px solid #e4e4e4;
box-shadow: none;
outline-width: 0;
width: 200px;
height: 20px;
font-size: 14px;
}

.input-pet-details-1{
border: none;
outline: none;
border-bottom: 2px solid #e4e4e4;
box-shadow: none;
outline-width: 0;
width: 440px;
height: 20px;
font-size: 14px;
}

.input-pet-details-2{
border: none;
outline: none;
border-bottom: 2px solid #a1a1a1;
box-shadow: none;
outline-width: 0;
width: 200px;
height: 20px;
font-size: 14px;
background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown{
border: none;
outline: none;
border-bottom: 2px solid #e4e4e4;
width: 200px;
height: 20px;
box-shadow: none;
font-size: 13px;
padding: 0px;
text-align: left !important;
padding-left: 0 !important;
left: 0;
}

.label-petdata{
font-size: 12px;
color: #b4b4b4;
}

.label-petdata-1{
font-size: 14px;
color: #777777;
}

p strong{
font-size: 18px;
}

.color-gray{
color:  #b4b4b4;
}

::ng-deep .mat-radio-label-content {
font-weight: 550 !important;
}

.edit-parent input:focus{
border: none !important;
outline: none !important;
border-bottom: 2px solid #e4e4e4 !important;
box-shadow: none !important;
outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
outline: none !important;
}

input[readonly] {
background-color: white;
}

.submit-login{

width: 100%;
max-height: 35px;
border: none;
outline: none;
border-radius: 5px;
font-weight: 500;
}

.file{
border: none;
outline: none;
box-shadow: none;
background-color: #FCBC1B;
border-radius:5px;
width:100px;
height: 30px;
font-size: 13px;
}

.gender-male{
    font-weight: none;
    font-size: 13px;
}

.gender-female{
font-weight: none;
font-size: 13px;
}

.multiple-images{
transition: transform .3s;
}

.multiple-images:hover{
transform: scale(2.5);
}

@media only screen and (max-width:990px) {
  .marginheight{
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav{
    height: 100% !important;
  }
  .petparentlinks{
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login{
    margin-top: 20px !important;
  }
}


@media only screen and (max-width:850px){
  .body-login{
    width: 100%;
  }
  .pd-leftside-navigation{
    margin-left: -35px !important;
  }
  }
  
  @media only screen and (max-width:770px){
  .label-petdata{
    text-align: center;
  }
  .label-petdata-1{
    text-align: center;
  }
  
  .gender-female{
    margin-top: -40px !important;
  }
  .below-gender-move{
    margin-top: 40px;
  }
  .pd-leftside-navigation{
    margin-left: 15px !important;
  }
  .marginheight{
    margin-top: 0px !important;
  }
  
  }

  @media only screen and (max-width:565px){
    .pd-leftside-navigation{
      margin-left: -50px !important;
    }
    
    }
  
  @media only screen and (max-width:576px){
  .below-gender-move{
    margin-top: 0px;
  }
  .body-login{
    border-radius: 0px;
  }
  .left-side{
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons{
    margin-left: 0px !important;
  }
  .petparentlinks{
    margin-left: -50px !important;
  }
  
  }

  @media only screen and (max-width:510px){
    .marginheight{
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width:360px){
    .input-pet-details{
      width: 200px;
    }
    
    .input-pet-details-2{
    width: 200px;
    }
    
    .input-pet-details-dropdown{
    width: 200px;
    }
    .submit-login{
      width: 200px !important;
    }
    }
    
    @media only screen and (max-width:330px){
      .gender-female{
        margin-top: -40px !important;
      }
      .below-gender-move{
        margin-top: 40px;
      }
      .leftside-buttons{
        width: 160px !important;
      }
      .pd-leftside-navigation{
        text-align: left !important;
      }
      .petparentlinks{
        text-align: left !important;
        margin-left: -70px !important;
      }
      }
    
    @media only screen and (max-width:300px){
    .input-pet-details{
      width: 170px;
    }
    
    .input-pet-details-2{
    width: 170px;
    }
    
    .input-pet-details-dropdown{
    width: 170px;
    }
    }
  

/*  Media Queries Start */

@media only screen and (max-width:1044) and (min-width:850) {

  .mynavigator{
    margin-left: -70px !important;
  }

  .mynavigator a{
    margin-left: -170px !important;
  }
  
}

@media only screen and (max-width:1068px) {

  .mynavigator li{

    margin-left: -50px !important;
  }


  
}

@media only screen and (max-width:789px) {

  .mynavigator li{

    margin-left: -10px !important;
  }
  
}


@media only screen and (max-width:620px) {

  .mynavigator button{

    padding: 5px 10px !important;
  }
  
}

@media only screen and (width:768px) {

  .mynavigator li{
     margin-left: -50px !important;
  }
  
}

@media only screen and (max-width:1070px) and (min-width:647px) {

  .marginheight{
    margin-top: 20px !important;
  }
  
}


@media only screen and (max-width:480px) and (min-width:300px) {

  .mynavigator button{

    padding: 10px 15px !important;
  }
  .mynavigator li{

    padding: 10px 0px !important;
  }
  
}












@media screen and (min-width:1010px) {

  .image-upload{
    margin-left: 0px !important;
  }
  
  .mynavigator button{
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}


@media screen and (min-width:853px) {

  .image-upload{
    margin-left: 0px !important;
  }

  
  
}

@media (max-width:577px) {

  .background{
    padding: 45px 0px 0px 0px !important;
  }
  
}

@media screen and (max-width:500px) and (min-width:425px) {

  .image-upload{
    margin-right: 0px !important;
    margin-left: -50px !important;
  }
  .mynavigator{
    margin-left: 0px !important;
  }
  
}

@media screen and (max-width:378px) and (min-width:280px) {

  .mynavigator{
    margin-left: 0px !important;
  }
  
}

@media screen and (max-width:770px) and (min-width:760px) {

  .mynavigator{
    margin-left: 50px !important;
  }
  
}

@media screen and (max-width:607px) and (min-width:576px) {

  .myform col{
    margin-bottom: 5px !important;
  }

  .background{
    padding: 45px 0px 20px 20px !important; ;
  }


}


@media screen and (max-width:480px) {

  .submit-login , .cancel-btn{
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
  
}

/*  Media Queries End */




























































































































      

      /* my own pet parent */      
      /* @media screen and (max-width:1023px) and (min-width:851px) {
    
         .image-upload{
          margin-left: -65px !important;
          margin-right: 40px !important;
        } 
    
        .mynavigator{
          margin-left: -35px !important;
        }
    
        .mynavigator button{
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
        
      }
      @media screen and (max-width:1008px) and (min-width:970px) {
    
        .image-upload{
          margin-right: 40px !important;
        }
        
      }
    
      @media screen and (max-width:931px) and (min-width:770px){
        .mynavigator ul{
          margin-left: 0px !important;
        }
    
        .mynavigator li{
          width: fit-content !important;
        }
        
      } */
    /* mobile fixed */
    
    /* @media screen and (max-width:370px) and (min-width:350) {
      .image-upload{
        margin-right: -185px !important;
    
      }
      .mynavigator {
        margin-right: -180px !important;
        margin-left: 100px !important;
    
      }
      
    }
    
    @media screen and (max-width:372px) and (min-width:300px) {
    
      .image-upload{
        margin-left: 300px !important;
      }
    
      .mynavigator{
        margin-left: 300px !important;
      }
      
    }

    
  @media screen and (max-width:300px) {

    .myform{
      margin-left: -10px !important;
    }

    .input-pet-details{
      width: 90px !important;
    }
    
    .input-pet-details-2{
    width: 90px !important;
    }
    
    .input-pet-details-dropdown{
    width: 90px !important;
    }

    .overlay{
      
      position: absolute !important;
      height: 100px !important;
      left: 50% !important;
      top: 10px !important;
    }


    
  }

  @media screen and (max-width:1170px) and (min-width:873px) {
                  
    .pd-leftside-navigation{
      margin-left: 15px !important;
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
    }

                 
    .pd-leftside-navigation button{
      
      display: flex !important;
      width: 80% !important;
      
    }

    .pd-leftside-navigation button{
      padding: auto,20px !important;
    }
     
  }

  @media screen and (max-width:873px) and (min-width:700px){

                 
    .pd-leftside-navigation{
      margin-left: 0px !important;
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
    }

                 
    .pd-leftside-navigation button{
      
      display: flex !important;
      width: 80% !important;
      
    }

    .pd-leftside-navigation button{
      padding: auto ,20px !important;
      
    }


    


  }


@media screen and (max-width:500px) and (min-width:378px) {

  .image-upload{
    padding-right: 0px !important;
    width: 100% !important;
  }

  .image-upload img{
    margin-right: 0px !important;
  }

  .pd-leftside-navigation{
    margin-left: 0px !important;
  }

  
  
}


@media screen and (max-width:770px) and (min-width:76px) {

  .image-upload{
    margin-right: 700px !important;
  }
  
} */

















/* 
@media only screen and (max-width:576px){
.below-gender-move{
margin-top: 0px;
}
.body-login{
border-radius: 0px;
}
.left-side{
border-radius: 0px 0 0 0px;
}
.leftside-buttons{
margin-left: 0px !important;
}
}

@media only screen and (max-width:360px){
.input-pet-details{
    width: 200px;
}

.input-pet-details-2{
width: 200px;
}

.input-pet-details-dropdown{
width: 200px;
}
}

@media only screen and (max-width:330px){
    .gender-female{
    margin-top: -40px !important;
    }
    .below-gender-move{
    margin-top: 40px;
    }
    .leftside-buttons{
    width: 160px !important;
    }
    }

@media only screen and (max-width:300px){
    .input-pet-details{
        width: 170px;
    }

    .input-pet-details-2{
        width: 170px;
    }

    .input-pet-details-dropdown{
        width: 170px;
    }
} */



/* My new media queries */
/* My new media queries */


/* @media only screen and (max-width:480px) and (min-width:300px) {
    .image-upload{
        margin-right: 250px !important;
        margin-left: -40px !important;
    }
    .left-side{
        padding-left: 0px;
        padding-right: 0px;
    }
    .background{
        height: max-content;
      }
}



@media only screen and (max-width:480px) {
    .image-upload{
        margin-right: 50px !important;
        margin-left: -8px !important;
    }
    .left-side{
        padding-left: 0px;
        padding-right: 0px;
    }

    .background{
        height: max-content;
      }
}

@media only screen and (max-width:768px) and (min-width:480px) {

    .image-upload{
        margin-left: 100px !important;
        text-align: center;
    }

    .background{
        height: max-content;
      }
    
}
@media only screen and (max-width:1024px) and (min-width:768px) {

    .image-upload{
        margin-right: 150px !important;
        margin-left: -50px !important;
    }

    .background{
        height: max-content;
      }
    
}
@media only screen and (max-width:1208px) and (min-width:1024px) {

    .image-upload{
        margin-right: 50px !important;
        margin-left: -10px !important;
    }

    .background{
        height: max-content;
      }
    
} */