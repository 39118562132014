.started{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    background-color: white;
    text-align: center;
    padding: 20px;
}
.started > h1{
    padding: 20px;
    margin-top: 30px;
    font-size: 45px;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 35px !important;
    color: #212529;
    padding-bottom: 50px;
}
.started__tiles{
    /* padding: 40px; */
    justify-content: space-evenly;
    display: flex;
}
.started__tile{
    width: 300px;
    padding:40px ;
    padding-top: 0;
    padding-bottom: 80px;
    margin: 20px;
    background-color:#ececec ;
}
.started__tile> img{
    padding-top: 0;
    margin-top: 0;
    width: 240px;
    height: 240px;
}
.started__tile> p{
    font-size: 20px;
    margin-bottom: 45px;
}
.started__tile> a{
    background-color: #ffc107;
    border-radius: 2px;
    margin: 12px;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
}
.started__tile > a:hover{
    background-color:#d39e00 !important ;
}
.started__how
{
    padding-bottom: 100px !important;
    margin: auto ;
    max-height: 750px !important;
    width: 70% !important;
}
@media (max-width: 800px) {
    .started__tiles{
        flex-direction: column;
    }
    .started__tile{
        align-self: center;
        
    }

    .started h1{
        
        font-size: 24px !important;
         padding-left: 0px !important;
         padding-right: 0px !important;
    }

    .started__tile{
        width: 100% !important;
        margin: 25px !important;
        height: auto !important;
    }

    .started__tile img{
             text-align: center !important;
             width: 80% !important;
             height: auto !important;
    }

    .started__how{
        margin: 0 !important;
        width: 100% !important;
        height: auto !important;
    }
}

@media screen and (max-width:800px){

    /* .started__tile{
        width: 80% !important;
    } */

    .started h1{
           font-size: 38px !important;
    }
    
}

@media screen  and (max-width:800px) and (min-width:500px){

    .started__tile{
        width: 400px !important;
        margin: 30px 10px !important;
    }
    
}
@media screen and (max-width:800px) and (min-width:500px) {

    
    .started h1{
        font-size: 38px !important;
 }

    
}

@media screen and (max-width:500px) and (min-width:400px) {
    .started h1{
        font-size: 28px !important;
    }
    
    .started__tile{
        width: 300px !important;
        margin: 30px 10px !important;
    }
}

@media screen and (max-width:400px) {

    .started h1{
        font-size: 22px !important;
    }
    
    .started__tile{
        width: 110% !important;
    }

    .started__how{
        width: 100% !important;
    }
}


@media only screen and (max-width:307px)
{
    .started__tile > a{
        width:200px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    
}