.sidenav-container {
    width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: black;
    padding: 20px;
}
.background{
    height: fit-content !important;
}
.my-tags1{
    color: black;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    border:none;
    outline:none;
    box-shadow: none;
    background-color: rgb(250, 250, 250);
    margin-left: -3px;
}

.pet-information-active{
    float: left;
    width: 1000px;
    margin-top: 30px;
    margin-left: 80px !important;
    /* background-color: rgba(231, 227, 227, 0.6); */
    background-color: white;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 10px;
}

.dropbtn{
    font-size: 18px !important;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    margin-left: -35px;
    margin-top:5px;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-item-1{
      width: 100% !important;
  }
  
  .dropdown-content-1{
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    margin-top:5px;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content-1> a {
    width: inherit;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content-1 a:hover {
    background-color: black; 
    color:white; 
    text-decoration: none;
  }
  
  .dropdown:hover .dropdown-content-1 {
    display: block;
  }
  
  .dropdown-content> a {
    width: inherit;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content .dropdown-item-1:hover {
    background-color: black; 
    color:white; 
    text-decoration: none;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown:hover .dropbtn {
    background-color: inherit;
  } 
  
  .profile-picture{
    width: 70px;
    height: 70px;
    margin: auto;
    margin-top: 15px;
}













.leftside-details{
    border: 2px solid rgb(8, 73, 194);
    border-radius: 15px;
    padding: 20px;
}

.leftside-blocks1{
    height: 80px;
    width: 25px;
    border: 2px solid  rgb(140, 140, 143);
    margin-right: 25px;
    margin-left: 30px;
}

.leftside-blocks2{
    height: 80px;
    width: 25px;
    border: 2px solid rgb(140, 140, 143);
    margin-right: 30px;
}

.rightside-blocks{
    height: 120px;
    border: 2px solid  rgb(175, 175, 175);
    background-color: white;
    padding-top: 5px !important;
}

.rightside-blocks-image{
    margin-left: -13px;
}

.rightside-vaccinations{
    font-size: 13px;
    margin-top: 30px;
}

.rightside-blocks-middle{
    font-size: 40px;
    font-weight: 500;
}

.notification-text{
    font-size: 11px;
}

.fa-bell{
    margin-left: -5px;
    margin-top: 10px;
}

.rightside-button{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
    text-align-last:center;
}

.rightside-button-1{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
}

.rightside-button-2{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
}

.rightside-button-add-record{
    width: 100%;
    border: none;
    outline: none;
    box-shadow: none;
}

.rightside-medical-details{
    border: 2px solid rgb(202, 202, 202);
    margin-top: 30px;
    border-radius: 50px;
    margin-left: 50px;
    margin-right: 15px;
    width: 330px;
    min-width: 265px !important;
}

.rightside-medical-details-image{
    margin-left: 10px;
    margin-top: 3px;
}

.rightside-medical-details-vaccinations{
    font-size: 18px;
    font-weight: 500;
    word-wrap: break-word !important;
}

.rightside-medical-details-vaccination-name{
    color: gray;
    font-size: 14px;
}

.medicaldetails{
    margin: auto;
}

.menu-options{
    float: right;
}

button:focus{
    border: none;
    outline: none;
    box-shadow: none;
}

.rightside-medical-details-vaccination-info{
    color: rgb(156, 156, 156);
    font-size: 14px;
}

.darkgray{
    color: rgb(129, 129, 129);
}

.rightside-medical-details-vaccination-info1{
    color: rgb(156, 156, 156);
    font-size: 14px;
}

.rightside-medical-details-vaccination-notes{
    color: rgb(156, 156, 156);
    font-size: 14px;
}

.rightside-medical-details-vaccination-reminder{
    color: rgb(156, 156, 156);
    font-size: 14px;
    margin-top: -11px;
}

.attachfile-icon{
    transform: rotate(45deg);
}

.rightside-blocks-with-details-card{
    display: inline-block;
}

@media only screen and (max-width:1400px){
    .hidecols{
        display: none;
    }
}


@media only screen and (max-width:1024px){
    .rightside-blocks-with-details-card{
        margin-left: 10px !important;
    }
}

@media only screen and (max-width:993px){
    .rightside-blocks-4{
        margin-left: 50px;
    }

    .widthdecrease{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

}



@media only screen and (max-width:900px){
    .rightside-blocks-with-details-card{
        margin-left: 30px !important;
    }
}

@media only screen and (max-width:800px){
    .rightside-blocks-with-details-card{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:769px){
    .rightside-blocks-4{
        margin-left: 0px;
    }

    .rightside-blocks-3{
        margin-left: 50px;
    }

    .rightside-blocks-5{
        margin-left: 50px;
    }

    .rightside-blocks-with-details-card{
        margin-left:100px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:577px){
    .rightside-blocks-with-details-card{
        margin-left: -50px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:412px){
    .rightside-blocks-with-details-card{
        margin-left: -80px !important;
        margin-right: 0px !important;
    }
}

@media only screen and (max-width:400px){
    .rightside-medical-details-vaccination-notes{
        width: 200px !important;
    }

    .rightside-medical-details{
        width: 250px !important;
    }
}

@media only screen and (max-width:376px){
    .body-medical-dashboard{
        margin-top: 50px !important;
    }

    .rightside-blocks-with-details-card{
        margin-left: -70px !important;
        margin-right: 20px !important;
    }
    
}

@media only screen and (max-width:361px){
    .body-medical-dashboard{
        margin-top: 50px !important;
    }

    .rightside-blocks-with-details-card{
        margin-left: -80px !important;
        margin-right: 20px !important;
    }
    
}


@media only screen and (max-width:335px){

    .rightside-blocks-with-details-card{
        margin-left: -90px !important;
        margin-right: 20px !important;
    }
    
}

@media only screen and (max-width:320px){

    .rightside-button-1{
        margin-left: 0px !important;
    }
    .rightside-button-2{
        margin-right: 10px !important;
    }
    .rightside-blocks-with-details-card{
        margin-left: -100px !important;
        margin-right: 20px !important;
    }
}

@media only screen and (max-width:300px){
    .rightside-blocks-with-details-card{
        margin-left: -110px !important;
        margin-right: 30px !important;
    }
}

@media only screen and (max-width:282px){
    .rightside-blocks-with-details-card{
        margin-left: -120px !important;
        margin-right: 45px !important;
    }
}

@media only screen and (max-width:1420px){
    .pet-information-active{
        width: 800px;
    }   
}

@media only screen and (max-width:1320px){
    .pet-information-active{
        width: 700px;
    }   

    .AddRecordDialog
    {
        width: fit-content !important;
        display: flex !important;
    }

    .Add_New_Record_Dialog
    {
        width: 400px !important;
        padding: 0px !important;
    }
}
@media only screen and (max-width:1160px){
    .dashboard__blacksidenav{
        height: 100% !important;
    }
}


@media only screen and (max-width:1120px){
    .pet-information-active{
        width: 600px;
    }
    .profile-picture{
        width: 50px;
        height: 50px;
    }
    .rightside-medical-details-vaccinations{
        font-size: 16px;
        font-weight: 500;
    }
    .sidenav-container{
        margin-right: 30px !important;
    }
}

@media only screen and (max-width:1020px){
    .pet-information-active{
        width: 500px;
    }
    .profile-picture{
        width: 40px;
        height: 40px;
    }
    .rightside-medical-details-vaccinations{
        font-size: 15px;
        font-weight: 500;
    }
    .sidenav-container{
        margin-right: 30px !important;
    }
    .rightside-blocks-measurement{
        margin-right: 40px !important;
    }
}

@media only screen and (max-width:992px){
    .pet-information-active{
        width: 800px;
    }
    .profile-picture{
        width: 70px;
        height: 70px;
    }
    .rightside-medical-details-vaccinations{
        font-size: 18px;
        font-weight: 500;
    }
    .overallbody{
        padding: 60px 100px !important;
    }
    .row-sort-filter{
        width: 800px !important;
        margin-top: 30px;
    }
}

@media only screen and (max-width:920px){
    .menu{
        margin-left: -50px !important;
    }
}

@media only screen and (max-width:800px){
    .pet-information-active{
        width: 700px;
    }
    .overallbody{
        padding: 60px 20px !important;
    }
    .row-sort-filter{
        width: 700px !important;
    }
}

@media only screen and (max-width:730px){
    .pet-information-active{
        width: 600px;
    }
    .overallbody{
        padding: 60px 20px !important;
    }
    .row-sort-filter{
        width: 600px !important;
    }
    .profile-picture{
        width: 50px;
        height: 50px;
    }
    .rightside-medical-details-vaccinations{
        font-size: 16px;
        font-weight: 500;
    }
}

@media only screen and (max-width:620px){
    .pet-information-active{
        width: 500px;
    }
    .overallbody{
        padding: 60px 10px !important;
    }
    .row-sort-filter{
        width: 500px !important;
    }
    .profile-picture{
        width: 40px;
        height: 40px;
    }
    .rightside-medical-details-vaccinations{
        font-size: 14px;
        font-weight: 500;
    }
}

@media only screen and (max-width:570px){
    .medpetparentlinks{
        margin-left: -50px !important;
    }
}

@media only screen and (max-width:520px){
    .forsnallsizecss{
        display: block !important;
    }
    .forbigsizecss{
        display: none;
    }
}

@media only screen and (max-width:500px){
    .menu{
        margin-left: 0px !important;
    }
}

@media only screen and (max-width:480px){
    .row-sort-filter{
        width: 300px !important;
    }
}


@media only screen and (max-width:320px){
    .sidenav-container{
        width: 250px !important;
    }
    .row-sort-filter{
        width: 250px !important;
    }
}

@media only screen and (max-width:290px){
    .rightside-blocks-with-details-card{
        margin-left: -100px !important;
    }
}
/* Media Queries */

@media only screen and (max-width:1208px) and (min-width:1024px)
{

}

@media screen and (max-width:650px) and (min-width:397px) {

    .sidenav-container button{
        margin-top: -10px !important;
        padding: 0px !important;
        width: fit-content !important;
    }
    
    .sidenav-container div{
        margin-bottom: 10px !important;
    }
    
}

@media only screen and (max-width:575px)
{
    .row-sort-filter
    {
        
        width: 250px !important;
        text-align: left !important;
        /* background-color: aqua !important; */
       
    }

    .filter-div,.sort-div,.add-div
    {
        width: fit-content !important;
    }
}

@media only screen and (max-width:522px)
{
    .no-doc-upload
    {
        margin-left: -15px !important;
    }

    .remaining-info-div span
    {
        margin-left: 5px !important;
    }
    
}

@media only screen and (max-width:480px)
{
    .row-sort-filter
    {
        display: flex !important;
        flex-direction: column !important;
    }

    .sort-div
    {
        margin-left: 40px !important;
    }

    .filter-div
    {
        margin-left: -10px !important;
    }
    
}

@media only screen and (max-width:400px) and (min-width:280px) {

    .rightside-blocks{
        margin-right: 2px !important;
    }

    .my-tags1{
        margin-top: -10px !important;
        font-size: medium !important;
        padding-right: 0px !important;
        margin-right: 0px !important;
    }

    .dropdown-content{
        position: relative !important;
        width: 70% !important;
        height: fit-content !important;
        padding-top: 5px !important;
        margin-left: 20px !important;
        margin-right: -10px !important;
        padding-right: 0px !important;
        text-align: center !important;
    }
    .btn-ap-date{
        width: fit-content !important;
    }

    .dropdown-item-1{
        padding-right: 0px !important;
        text-align: justify !important;
        height: fit-content !important;
        font-size: 13px !important;
        margin-left: 30px !important;
    }
    .fa-arrow-down{
        margin-left: 5px !important;

    }
    .fa-arrow-up{

        margin-left: 10px !important;
        
    }
    
}

@media screen and (max-width:768px) and (min-width:400px){

    .rightside-blocks{
        margin-right: 2px !important;
    }

    
}



@media screen and (max-width:652px) and (min-width:400px){

    /* .dropdown-item-1{
        padding-right: 0px !important;
        text-align: justify !important;
        height: fit-content !important;
        font-size: 13px !important;
        margin-left: 30px !important;
    }

    
    .dropdown-content{
        position: relative !important;
        width: fit-content !important;
        height: fit-content !important;
        padding-top: 0px !important;
        margin-left: 80px !important;
        margin-right: -10px !important;
        padding-right: 10px !important;
        padding-left: 10px !important;
        text-align: center !important;
    }

    .btn .dropdown-item-1{
        width: 100% !important;
    }
     */
}

@media only screen and (max-width:400px)
{
    /* .sort-div
    {
        margin-left: 0px !important;
    }
    .dropdown-content
    {
        width: 200px !important;
    }
    .dropdown-content button
    {
        text-align: left !important;
        margin-left: 0px !important;
    } */

    .sort-div
    {
        text-align: left !important;
        
    }
    
    .dropdown-content
    {
        width: fit-content !important;
        margin-left: -50px !important;
        margin-right: 0px !important;
        
    }
    .dropdown-content button
    {
        margin-left: 0px !important;
    }
}

/* Media Queries for Responsive Cards Start */


@media only screen and (max-width:521px)
{

  .pet-information-active
  {
      background-color: white !important;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 55px !important;
      width: 325px !important;
      margin-left: 35px !important;
      
  }
  
    .my-p
    {
      display: flex !important;
      flex-direction: row !important;
    }

    .besides
    {
      float: right !important;
      
    }

    .innermost-info1 , .innermost-info2
    {
      flex-direction: row !important;
    }


    .reminder-doc
    {
      flex-direction: column !important;
      margin-top: 2px !important;
      margin-bottom: 2px !important;
    }


    .top-row
    {
      margin-left: 1px !important;
      margin-top: -1px !important;
      width: fit-content !important;
    }
    
    
    .med-card-title
    {
      position: relative !important;
      top: -65px !important;
      left: 55px !important;
      font-size: 15px !important;
      font-weight: 500 !important;
     
      
    }
    .second-row
    {
      display: flex !important;
      flex-direction: row !important;
      /* background-color:greenyellow !important; */
      justify-content: space-between !important;
      margin-top: -60px !important;
      margin-left: 55px !important;
      width: 100% !important;
      color:rgba(0, 0, 0, 0.2)!important;
      font-size: 14px !important;
    }


    .float-left
    {
      margin-top: 0px !important;
    }

    .remaining-info-div
    {
      margin-top: -40px !important;
      display: flex !important;
      flex-direction: column !important;
    }
    
    .my-notes
    {
      margin-top: 10px !important;
    }

    

    .innermost-info2
    {
      display: flex !important;
      flex-direction: row !important;
    }

    

    .doc-div
    {
      margin-top: 5px !important;
    }

    .diagnos-by , .diagnos-on
    {
      flex-direction: column !important;
    }

    

    .main-logo-img
    {
      height: 35px !important;
      width: 35px !important;
      margin-top: -1px !important;
    }

    .pet-info-inner
    {
      display: flex !important;
      flex-direction: column !important;
    }
    
  
}

@media only screen and (max-width:481px)
{
    .pet-information-active
    {
        margin-left: 45px !important;

    }

    
}

@media only screen and (max-width:420px)
{
    .pet-information-active
    {
        margin-left: 25px !important;
    }
    
}

@media only screen and (max-width:378px)
{
    .pet-information-active
    {
        width: 280px !important;
    }
    
}

@media only screen and (max-width:308px)
{
    .pet-information-active
    {
        width: 280px !important;
        margin-left: 5px !important;
    }
    
}
@media only screen and (max-width:285px)
{
    .dropdown-content
    {
        width: 220px !important;
    }
    
}

/* Media Queries for medical count start */

@media only screen and (max-width:1022px)
{
    .measurement-block
    {
        width: 70% !important;
    }
    
}

@media only screen and (width:1024px)
{
    .measurement-block
    {
        width: 60% !important;
        margin-right: 40px !important;
    }
    
}

@media only screen and (max-width:990px)
{
    .major-rightside-block div
    {
        margin-left: 10px !important;
        margin-right: 0px !important;
    }

    .measurement-block
    {
        width: 100% !important;
    }

    
}

@media only screen and (max-width:768px)
{
    .measurement-block
    {
        width: 105% !important;
        
    }
    
}
/* Media Queries for medical count ends */