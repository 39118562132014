.home{
    width: 100% !important;
    overflow-x: hidden !important;
}


.home__landing{
    padding: 0;
    background-color: #fdcb4d;
    background-image: url(./07.png);
    min-height: 100vh !important;
    display: flex;
    align-items: center;
}

.home__para>h2{
    font: 400 24px/32px Roboto,Helvetica Neue,sans-serif;
    font-weight: 700  !important;
    font-size: 24px;
}
.home__para{
    flex: 0.43;
    text-align: left;
    padding: 30px;
    padding-right: 0;
    margin-right: 0;
    color: #212529;
}
.home__para >p{
    font:400 24px/32px Roboto,Helvetica Neue,sans-serif;
    padding-top:30px ;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 1px;
}
.home__videoIcon{
    padding-top: 20px;
    margin-top: 20px;
    padding-right: 30px;
}
.home__carousel{
    flex: 0.60;
    padding: 20px;
}
button.rec-dot{
    /* visibility: hidden !important; */
    position: relative !important;
    margin-top: -50px;
    z-index: 100;
    background-color: #bfbcbd;
  /* box-shadow: 0 0 1px 3px rgba(235, 16, 16, 0.5); */
}
button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus .rec-dot_active {
    background-color: white;
    box-shadow: none;
    border: none;
    outline: none;
  }
.ihThzJ{
    background-color: white !important;
    box-shadow: none !important;
}
.facebook{
    height: 60%;
    width: 100%;
}
.carousel__image{
    height: 60%;
    width: 100%;
}
*{
    padding: 0;
    margin: 0;
}

/* square buttons */
.rec.rec-arrow {
    border-radius: 0;
    visibility: hidden;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
    border-radius: 12px;
    height: 50px;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}
.boxplay{
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.playbutton .playbutton:focus{
    animation: zoomeffect 2s infinite;
    border-radius: 50%;
    z-index: 1;
    color: black;
    background-color: transparent;
    box-sizing: border-box;
}

.play-btn:focus .boxplay:focus .playbuttonimage:focus{
    background-color: #fdcb4d !important;
}

.boxplay >span{
    position: absolute;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    color: #212529;
    text-align: left;
    -webkit-animation: animates 3s linear infinite;
    animation: animates 3s linear infinite;
    animation-delay: 0s;
    -webkit-animation-delay: calc(0.8s*var(--i));
    animation-delay: calc(0.8s*var(--i));
}

.span1{
    --i: 1; 
    background-color: rgb(182, 167, 102);
}

.span2{
    --i: 2; 
    background-color: rgb(182, 167, 102);
}

.span3{
    --i: 3; 
    background-color: rgb(182, 167, 102);
}

.started__how
{
    padding-bottom: 100px !important;
    margin: auto ;
    max-height: 750px !important;
    width: 70% !important;
}

@keyframes zoomeffect{
    0%{
      background-position:center;
      transform:scale(1,1);
    }
    50%{
      background-position:center;
      transform:scale(1.3,1.3);
    }
    100%{
      background-position:center;
      transform:scale(1,1);
    }
  }
@keyframes animates{
    0% {
        width: 50px;
        height: 50px;
        opacity: .8;
        background: transparent !important;
    }
    50% {
        opacity: .5;
    }
    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}
@media (max-width: 770px) {
    .home__landing{
        flex-direction: column-reverse;
    }

    .new_para_home{
        margin-top: 260px !important;
    }
    .home__carousel{
        margin-top: 0px !important;
    }

}

@media (min-width: 770px) {
    .home__landing{
        margin-top: -90px !important;
    }

    .home__carousel{
        margin-top: -100px !important;
    }

}

@media screen and (max-width:850px) {

    .rec-carousel{
        position: relative !important;
    }
/* check */
    .button.rec-dot{
        position: absolute;
        left: 50% !important;
        bottom: 50px !important;

        margin-top: -50px !important;
        
    }
    
}

@media screen and (max-width:650px){
    .rec-pagination{
        position: relative; 
        top: -60 px !important;   
    }

    .rec-dot{
        height: 8px !important;
        width: 8px !important;
    }
}

@media only screen and (max-width:310px)
{
    .video-play-btn
    {
        width: 150px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
}

@media (max-width:768px) and (min-width:280px) {
    .home__carousel{
        margin-top: 35px !important;
        position: relative !important;
        width: 100% !important;
        max-width: 700px !important;
        height: 100% !important;
    }

    .home__para{
        margin-top: 0px !important;
        margin-left: 25px !important;
        margin-right: 25px !important;
        margin-bottom: 25px !important;
        font-size: medium;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .home__para h2{
        font-size: 28px !important;
    }
    
    
    .home__para p{
        padding-top: 8px !important;
        font-size: 15px !important;
    }
}



@media (max-width:1600px) and (min-width:1067px){

    .home__carousel{
        margin-top: 60px !important;
    }
    
}

@media (max-width:500px) {
    .home__para{
        margin-top: 0px !important;
    }
}