@import url('https://fonts.googleapis.com/css2?family=Diplomata+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Federant&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gorditas&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fruktur&family=Permanent+Marker&display=swap');
@import url('http://db.onlinewebfonts.com/c/32070eac9c9ad4cb61f359f319bb34ac?family=AbsolutDB');
@import url('http://db.onlinewebfonts.com/c/69034ec1a721c306a74b043f4138a7ed?family=BellCent+NamNum+BT');
@import url('http://db.onlinewebfonts.com/c/0ad0845267e2516d7293374db9d949c5?family=KibbyBoldFont');


.pet-poster-background{
    width: 60%;
    height: 100%;
    margin: auto;
    border: 1px solid rgb(202,167,82);
    text-align: center;
}

.image{
    box-shadow: 0px 0px 20px 20px #e5bd55;
    border-radius: 200px;
}

.header-font{
    font-family: 'AbsolutDB';
    color: rgb(202,167,82);
}

.header-text{
    font-size: 80px;
    float: center;
    /* margin: auto; */
    margin-top: 30px;
    padding-bottom: 50px;
    margin-bottom: 30px;
}

.mat-card{
    width: 80%;
    margin: auto;
    margin-top: 30px;
    border: 1px solid  #cca151;
}

.mat-card-header{
    margin-left: 18%;
}

.lost-info-header{
    font-size: 35px;
    line-break: normal;
    line-height:normal;
    padding-top: 10px;
    padding-right: 15px;
}

.lost-info-text{
    color:  #cca151;
    font-size: 21px;
    margin-top: -20px !important;
}

.lost-info-text-address{
    color:  #cca151;
    font-size: 21px;
    margin-top: -10px !important;
}

.answers-to-name{
    width: 100%;
    background-color: rgb(102, 89, 32);
    color: white;
    margin: auto;
}

.answers-to-name-header{
    font-family: 'KibbyBoldFont';
    font-size: 25px;
    transform: scale(1.6,1.4);
}

.answers-to-name-text-1{
    font-family: 'AbsolutDB';
    font-size: 17px;
    transform: scale(1.3,1.1);
    font-weight: 600;
}

.answers-to-name-text{
    font-family: 'KibbyBoldFont';
    font-size: 25px;
    transform: scale(2.5,2);
    font-weight: 700;
}

.bottom-help-text{
    font-size: 17px;
    transform: scale(1,1);
}

.bottom-text{
    font-family: 'BellCent NamNum BT';
    color: rgb(202,167,82);
    font-size: 27px;
    transform: scale(1.2,1.6);
}

.bottom-text-1{
    font-family: 'BellCent NamNum BT';
    color: rgb(202,167,82);
    font-size: 27px;
    transform: scale(1.6,1.6);
}

.reward-header{
    font-size: 25px;
    transform: scale(1.5,1.8);
}

.yellow-color{
    color: rgb(202,167,82);
}

.button-styling{
    margin:10px;
    font-size: 16px;
    border: none !important;
    outline: none !important;
}

@media only screen and (max-width:1120px){
    .answers-to-name-header{
        font-size: 18px;
    }
    .lost-info-header-res{
        font-size: 28px;
    }
}
@media only screen and (max-width:922px){
    .answers-to-name-header{
        font-size: 15px;
    }
    .lost-info-header-res{
        font-size: 22px;
        line-break: normal;
    }
    .postar-res{
      padding: 5px 10px;
      font-size: 10px;
    }
    .button-styling{
        margin:10px;
        font-size: 18px;
        padding: 5px;
    }
}
@media only screen and (max-width:768px){
    .lost-info-header-res{
        font-size: 20px;
        line-break: normal;
    }
    .button-styling{
        width: 100% !important;
    }
}
@media only screen and (max-width:690px){
    .header-text{
        line-height: 4rem;
        font-size:40px;
    }
    .lost-valuable{
        margin-top: 10px !important;
        margin-bottom: 0px !important;
    }
    .answers-to-name-header{
        font-size: 12px !important;
    }
    .button-styling{
        margin:10px;
        font-size: 18px;
        padding: 5px;
    }
}
@media only screen and (max-width:500px){
    .answers-to-name-header{
        font-size: 10px !important;
    }
    .lost-data-header{
        font-size: 35px !important;
    }
}
@media only screen and (max-width:450px){
    .answers-to-name-header{
        font-size: 8px !important;
    }
}
@media only screen and (max-width:400px){
    .mat-card{
        width: 80%;
    }
    .header-text{
        line-height: 4.5rem;
    }
   .lost-info-header{
       font-size: 20px;
   }

   .answers-to-name{
       padding: 40px !important;
   }
   
   .answers-to-name-header{
       font-size:14px !important;
   }
   .answers-to-name-text{
       font-size: 15px;
   }
   .bottom-text-1 {
       font-size:18px;
   }
   .reward-header{
       padding: 10px 0px 0px 0px;
   }
   .bottom-text{
       padding: 0px 0px 5px 0px;
       font-size: 20px !important;
   }
   .reward-header{
       font-size: 20px !important;
   }
}
@media only screen and (max-width:298px){
    .lost-info-header-respon{
        font-size: 18px;
    }
    .button-styling{
        margin:12px;
        font-size: 15px;
        padding: 10px;
    }
}

.bg-image{
    background-image: url('../../assets/pet_banners/POst Banner_11.jpg');
    background-size: 100% 750px !important;
    background-repeat: no-repeat;
    min-height: 750px;
}

.allowlocationtext{
    font-size: 22px;
    margin-top: 480px !important;
    animation: blinker 1s linear infinite;
    color: red;
}
.buttonforbutton{
    display: flex;
    /* align-items: center; */
    /* justify-content: space-around; */
}

.repFound-label{
    font-size: 12px;
}
.repFound-input{
    font-size: 14px  !important;
    padding: 5px !important;
    border: 1px solid lightgrey;
}
.repFound-input:focus{
    font-size: 14px  !important;
    padding: 5px !important;
    border: 1px solid lightgrey  !important;
}
.confirm{
    background-color: #ffc107 !important;
}
.confirm:disabled{
    background-color: #d3d3d3 !important;
}
.cancel:focus{
    padding:5px 10px;
        background-color: white;
        border:none;
        outline:none;
        box-shadow: 0px 2px 5px 0px #888888;
}

.lost-detail {
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
}
.lost-data {
    font-size: 22px;
}
.repFound-input{
    width: 100% !important;
}
.lost-info-detail {
    width: 100%;
    text-align: center;
}
.lost-data-header {
    font-size: 55px;
    text-align: center;
    margin-top: 30px;
}
.lost-container{
    padding-top: 15px;
    display: flex !important;
    align-items: center;
}
.flex{
    display: flex;
    align-items: center;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }


@media only screen and (max-width:1130px){
    .bg-image{
        min-height: 700px !important;
    }
}

@media only screen and (max-width:850px){
    .bg-image{
        background-size: 100% 600px !important;
        min-height: 600px;
    }

    .petfoundbutton{
        font-size: 16px !important;
        margin-top: 20px !important;
    }

    .allowlocationtext{
        margin-top: 10px !important;
    }

    .buttonforbutton{
        flex-direction: row !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

@media only screen and (min-width:770px)
{
    .mobnum__div
    {
        margin-left: 15px !important;
    }
    .counrty__div
    {
        margin-right: 25px !important;
    }
    
}

@media only screen and (max-width:770px)
{
    .row2
    {
        margin-top: -30px !important;
    }

    .report_found_email
    {
        margin-top: -10px !important;
    }
    .mob-number
    {
        margin-top: 13px !important;
    }
    .email-rep-found
    {
        margin-top: -5px !important;
    }

    .my-d-btn
    {
        margin-top: 10px !important;
    }
}

@media only screen and (max-width:768px) {

    .lost-detail {
        text-align: center !important;
    }
    .row2{
        flex-direction: column !important;
    }
    .mobile-num{
        margin-top: 30px !important;
    }

    .mob-num{
        display: flex !important;
    }
    

     .inner-dialog-div{

        width:285px !important

     }



    /* .emerd-dilog{
        width: 350px !important;
    } */

    /* .row2{
        flex-direction: column;
    }
    .ch-cont-code{
        width: 100% !important;
    }
     */
    .lost-data-header{
        margin-top: 20px;
    }
}

@media only screen and (max-width:650px){
    .bg-image{
        background-size: 100% 400px !important;
        height: 400px !important;
    }

    .allowlocationtext{
        margin-top: -120px !important;
        font-size: 20px;
    } 

    .petfoundbutton{
        font-size: 16px !important;
        height: 35px !important;
        width: 180px !important;
        padding: 1px !important;
    }

    .buttonforbutton{
        margin-top: -120px !important;
    }

    .buttonforbutton button
    {
        margin-top: 0px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -120px !important;
    }
    .inner-emerd-div
    {
        padding: 10px !important;
    }

    .allowlocationtext
    {
        margin-top: -190px !important;
    }

    .loc-buttonforbutton
    {
        margin-top: 30px !important
    }
}

@media only screen and (max-width:487px) and (min-width:480px)
{
    

    .buttonforbutton button
    {
        width: 170px !important;
    }
    
}

@media only screen and (max-width:480px){
    .bg-image{
        background-size: 100% 650px !important;
        height: 650px !important;
    }

    .allowlocationtext{
        margin-top: -140px !important;
        font-size: 18px;
    } 

    .major-pet-med-pro-detail
    {
        margin-top: -40px !important;
    }

    .my-d-btn
    {
        margin-bottom: 18px !important;
    }

    .petfoundbutton{
        font-size: 14px !important;
        height: 65px !important;
        width: 100px !important;
        padding: 0px !important;
        word-wrap: break-word !important;
        white-space: normal !important;
    }

    .buttonforbutton{
        margin-top: -40px !important;
    }

    .buttonforbutton .petfoundbutton{
        margin-top: 0px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -260px !important;
    }

    .my-d-btn{
        flex-direction: column !important;
        align-items: center !important;
    }

    .confirm, .cancel{
        width: 180px !important;
        padding: 4px 8px !important;
        margin: 5px 10px !important;
        height: fit-content !important;
    }

    .inner-dialog-div{

        width:220px !important

     }

     .allowlocationtext
     {
         margin-top: 0px !important;
     }

     .loc-buttonforbutton
     {
         margin-top: 30px !important;
     }
}

@media only screen and (max-width:350px)
{

    .bg-image{
        background-size: 100% 200px !important;
        height: 200px !important;
    }

    .allowlocationtext{
        margin-top: -240px !important;
        font-size: 16px;
    } 

    .petfoundbutton{
        font-size: 13px !important;
        height: 60px !important;
        width: 90px !important;
        padding: -10px !important;
    }

    .buttonforbutton{
        margin-top: -335px !important;
    }

    .buttonforbuttonsecond{
        margin-top: -330px !important;
    }

    .loc-buttonforbutton
    {
        margin-top: 0px !important;
        
       
    }
    .loc-buttonforbutton button
    {
        width:80px !important;
        height:50px !important
    }

    .major-pet-med-pro-detail .allowlocationtext
    {
        margin-top: -345px !important;
        font-size: 11px !important;
    }
}

@media only screen and (max-width:296px)
{
    .loc-buttonforbutton button
    {
        width: 105px !important;
        height: 45px !important;
        margin-top: -10px !important;
        padding-top: 2px !important;
    }

    .major-pet-med-pro-detail .allowlocationtext
    {
        margin-top: -350px !important;
        font-size: 11px !important;
    }

    
    
}

@media only screen and (max-width:288px)
{
    .loc-buttonforbutton button
    {
        width: 98px !important;
        height: 44px !important;
        padding-top: 0px !important;
        margin-top: -5px !important;
    }
    
}

@media screen and (max-width:310px) and (min-width:270px) {

    .buttonforbutton{
        display: flex !important;
        flex-direction: row !important;
    }

    .buttonforbutton{
        justify-content: center !important
    }

    .btn-div-1 button {
        margin-left: -20px !important;

    }

     .btn-div-2 .petfoundbutton{
         margin-right: -30px !important;

     }
    
}

/* Media Queries For Popups */

@media only screen and (max-width:410px){
    
    .activetag__PopUp
    {
      width: 250px !important;
      margin: 0px !important;
      padding: 0px !important;
    }
  
    .activetag__PopUp h1
    {
      text-align: center !important;
    }
  }


  @media only screen and (max-width:358px)
 {
     .activetag__PopUp
     {
         width: 280px !important;
     }
      
  }
    
  @media only screen and (max-width:305px)
  {
  
    .activetag__PopUp
    {
      width: 220px !important;
    }
    
  }
  































/* @media only screen and (max-width:310px) {

    .bg-image{
        background-size: 100% 200px !important;
        height: 200px !important;
        margin-top: 0px !important;
    }

     .my-form{
        margin-top: 500px !important;
    } 

    .buttonforbutton{
        padding: 0px !important;
        width: 100% !important;
        
    }
    .buttonforbutton .petfoundbutton{
        text-align: center !important;
    }
    .bg-image{
        padding: 0px !important;
    }

    .inner-dialog-div{

        width:200px !important

     }
    
    
} */