.card__confirm{
  
    flex: 0.5;
    width: 100%;
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #28a745;
    letter-spacing: 1px;
    padding: 7px;
    border: none;
    outline: none;
    border-radius: 5px ;
    color: white;
    box-shadow: 0px 2px 5px 0px #888888;
  }
  .card__confirm:hover{
    background-color:#1e7e34;
  }
  .card__cancel{
    box-shadow: 0px 2px 5px 0px #888888;
    width: 100%;
    flex: 0.5;
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #dc3545;
    letter-spacing: 1px;
    padding: 7px ;
    border: none;
    outline: none;
    border-radius: 5px ;
    color: white;
  }
  .card__cancel:hover{
    background-color: #bd2130;
  }

  /* Media Queries */

  @media only screen and (max-width:1000px)
  {
    .delet-med-record
    {
      display: flex !important;
      width: 650px !important;
    }
    
    
  }


  
  @media only screen and (max-width:540px)
  {
    .delete__dialog h1
    {
          text-align: center !important;
    }
    
  }

  @media only screen and (max-width:522px)
  {
    .docs-no-upload
    {
      margin-left: 25px !important;
    }

    .medcon-span
    {
      margin-left: 20px !important;
      background-color: chartreuse !important;
    }

    .med-con-div-1
    {
      width: fit-content !important;
      display: block !important;
    }
    
  }

  @media only screen and (max-width:498px)
  {
    .delete__dialog h1
    {
        margin-top: 2px !important;
    }
    
  }

  @media only screen and (max-width:467px) 
  {

    .delet-med-record
    {
      
      width: 320px !important;
    }


    
  }

  @media only screen and (mx-width:430px)
  {
    .major__dialog_btn button
    {
      margin: auto , 10px !important;
    }

    
    
  }

  @media only screen and (max-width:400px)
  {
    .delet-med-record
    {
      padding: 0px !important;

       
    }
    .delete-btn-grp
    {
      flex-direction: column !important;
    }
    
  }

  @media only screen and (max-width:340px)
  {
    .major__dialog_btn
    {
      flex-direction: column !important;
       width: 80% !important;
    }
    
  }

  .card__confirm{
  
    flex: 0.5;
    width: 100%;
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #28a745;
    letter-spacing: 1px;
    padding: 7px;
    border: none;
    outline: none;
    border-radius: 5px ;
    color: white;
    box-shadow: 0px 2px 5px 0px #888888;
  }
  .card__confirm:hover{
    background-color:#1e7e34;
  }
  .card__cancel{
    box-shadow: 0px 2px 5px 0px #888888;
    width: 100%;
    flex: 0.5;
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-size: 14px;
    font-weight: 500;
    background-color: #dc3545;
    letter-spacing: 1px;
    padding: 7px ;
    border: none;
    outline: none;
    border-radius: 5px ;
    color: white;
  }
  .card__cancel:hover{
    background-color: #bd2130;
  }

  /* Media Queries */

  @media only screen and (max-width:1000px)
  {
    .delet-med-record
    {
      display: flex !important;
      width: 650px !important;
    }
    
    
  }

  @media only screen and (max-width:623px) and (min-width:522px)
  {
    .top-row img
    {
      margin-left: -10px !important;
    }
    
  }

  @media only screen and (max-width:522px)
  {
    .breaking
    {
      display: none !important;
    }
    
  }

  @media only screen and (max-width:540px)
  {
    .delete__dialog h1
    {
          text-align: center !important;
    }
    
  }

  @media only screen and (max-width:498px)
  {
    .delete__dialog h1
    {
        margin-top: 2px !important;
    }
    
  }

  @media only screen and (max-width:467px) 
  {

    .delet-med-record
    {
      
      width: 320px !important;
    }


    
  }

  @media only screen and (mx-width:430px)
  {
    .major__dialog_btn button
    {
      margin: auto , 10px !important;
    }

    
    
  }

  @media only screen and (max-width:400px)
  {
    .delet-med-record
    {
      padding: 0px !important;

       
    }
    .delete-btn-grp
    {
      flex-direction: column !important;
    }
    
  }

  @media only screen and (max-width:340px)
  {
    .major__dialog_btn
    {
      flex-direction: column !important;
       width: 80% !important;
    }
    
  }

  @media only screen and (max-width:387px)
  {
    .my-p1
    {
      display:inline !important
    }
    
  }
 