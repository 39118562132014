.MuiFormControlLabel-label{
  font-size: 14px !important;
  color: #b4b4b4;
}

.body-login{
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  margin-top: 15px;
}

.image-upload{
  width: 120px;
  height: 120px;
  margin: auto !important;
}

#choose-profile-imageActi{
    display: none;
}

input{
  padding: 0px !important;
}

select{
  padding: 0px !important;
}

.mat-raised-button:disabled{
  background-color:#FCBC1B;
}

.image{
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.overlay{
  height: 120px;
  width: 120px;
  background-color: black;
  margin-top: -120px;
  position: absolute;
  opacity:0;
  border-radius: 50%;
}
.button-image{
  background-color: transparent !important;
  color: white !important;
  border: none ;
  outline: none;
}
.button-image:focus{
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  outline: none !important;
} 
.fa-edit{
  margin-top:48px;
  color: white;
}

.plus-icon{
  margin-top: 0px;
  color: #a1a1a1;
}

.image-upload:hover .overlay{
  background-color: rgba(0,0,0,0.5);
  opacity:1;
}

.leftside-navigation{
  list-style: none; 
  margin: auto; 
  margin-left: -30px;
}

#uploadPreviewActi{
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  border:none;
  outline:none;
}

#uploadPreview{
  width: 120px;
  height: 120px;
  margin-top: -140px !important;
  border-radius: 50%;
  display: none;
  border:none;
  outline:none;
}


.left-side{
  border-radius: 15px 0 0 15px;
  background-color: rgb(255, 234, 177);
}

.row-underline{
  border-bottom: 5px solid #FCBC1B;
}

.input-pet-details{
  border: none;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 200px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-1{
  border: none;
  outline: none;
  border-bottom: 2px solid #e4e4e4;
  box-shadow: none;
  outline-width: 0;
  width: 440px;
  height: 20px;
  font-size: 14px;
}

.input-pet-details-2{
border: none;
outline: none;
border-bottom: 2px solid #a1a1a1;
box-shadow: none;
outline-width: 0;
width: 200px;
height: 20px;
font-size: 14px;
background-color: rgb(255, 234, 177);
}

.input-pet-details-dropdown{
border: none;
outline: none;
border-bottom: 2px solid #e4e4e4;
width: 200px;
height: 20px;
box-shadow: none;
font-size: 13px;
padding: 0px;
}

.label-petdata{
  font-size: 12px;
  color: #b4b4b4;
}

.label-petdata-1{
font-size: 12px;
color: #777777;
}

p strong{
font-size: 18px;
}

.color-gray{
  color:  #b4b4b4;
}

::ng-deep .mat-radio-label-content {
  font-weight: 550 !important;
}

.edit-parent input:focus{
  border: none !important;
  outline: none !important;
  border-bottom: 2px solid #e4e4e4 !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.edit-parent input:focus,
select:focus,
button:focus {
  outline: none !important;
}

input[readonly] {
  background-color: white;
}

.submit-login{
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 500;
}

.file{
  border: none;
  outline: none;
  box-shadow: none;
  background-color: #FCBC1B;
  border-radius:5px;
  width:100px;
  height: 30px;
  font-size: 13px;
}

.gender-male{
    font-weight: none;
    font-size: 13px;
}

.gender-female{
font-weight: none;
font-size: 13px;
}

.multiple-images{
transition: transform .3s;
}

.multiple-images:hover{
transform: scale(2.5);
}


@media only  screen and (width:1024px)
{
  .leftside-navigation li
  {
    margin-right: 5px !important;
  
  }
  
}

@media only screen and (max-width:990px) {
  .marginheight{
    margin-top: 0px !important;
  }
  .dashboard__blacksidenav{
    height: 100% !important;
  }
  .petparentlinks{
    margin-top: -30px !important;
    margin-left: 1px !important;
  }
  .body-login{
    margin-top: 20px !important;
  }
}

@media only screen and (max-width:850px){
  .body-login{
    width: 100%;
  }
}
  
@media only screen and (max-width:770px){
  .label-petdata{
    text-align: center;
  }
  .label-petdata-1{
    text-align: center;
  }
  
  .gender-female{
    margin-top: -40px !important;
  }
  .below-gender-move{
    margin-top: 40px;
  }
  .leftside-buttons{
    margin-left: -50px !important;
  }
  .marginheight{
    margin-top: 0px !important;
  }
}
  
  @media only screen and (max-width:576px){
  .below-gender-move{
    margin-top: 0px;
  }
  .body-login{
    border-radius: 0px;
  }
  .left-side{
    border-radius: 0px 0 0 0px;
  }
  .leftside-buttons{
    margin-left: 0px !important;
  }
  .petparentlinks{
    margin-left: -50px !important;
  }
  }

  @media only screen and (max-width:510px){
    .marginheight{
      margin-top: 30px !important;
    }
  }
  
  @media only screen and (max-width:360px){
    .input-pet-details{
      width: 200px;
    }
    
    .input-pet-details-2{
    width: 200px;
    }
    
    .input-pet-details-dropdown{
    width: 200px;
    }

    .submit-login{
      width: 200px !important;
    }
    }
    
    @media only screen and (max-width:330px){
      .gender-female{
        margin-top: -40px !important;
      }
      .below-gender-move{
        margin-top: 40px;
      }
      .leftside-navigation{
        text-align: left !important;
      }
      .petparentlinks{
        text-align: left !important;
        margin-left: -70px !important;
      }
      }
    
    @media only screen and (max-width:300px){
    .input-pet-details{
      width: 170px;
    }
    
    .input-pet-details-2{
    width: 170px;
    }
    
    .input-pet-details-dropdown{
    width: 170px;
    }
    }
  

@media screen and (max-width:607px) and (min-width:576px) {

  /* .myform col{
    margin-bottom: 5px !important;
  } */

  .background{
    padding: 45px 0px 20px 20px !important; ;
  }


}


@media screen and (max-width:480px) {

  .submit-login , .cancel-btn{
    width: 100% !important;
    margin: 10px !important;
    height: 100% !important;
  }
  
}
