@import "~bootstrap/dist/css/bootstrap.min.css";

.img-fluid{
    margin-right: 150px;
    width: 100px;
    height: 60px;
    transition: transform .5s;
  }

.img-fluid:hover{
      transform: scale(1.1);
  }


footer{
    padding: 80px 0px;
}



.pattern1 {
    background-image: url("../../assets/images/patterns/pattern1.png");
    background-position:center bottom;
    background-repeat:repeat-x;
}

.list-unstyled{
    list-style-type: none;
}

.logo-footer{
    width:100px;
    height: 60px;
    margin-bottom: 10px;
 }

.social-list{
    margin-left: 0px;
}

.credits p{
    font-weight:700;
    font-size:13px;
    margin-bottom:0px;
    text-align:center;
}

.blog-box .blog-box-footer {
    padding: 0px 25px 35px 20px;
    font-size: 0.9em;
    text-align: center;
    background-color:#f4f4f4;
}
.blog-box .blog-box-footer > div {
    display: inline-block;
    margin-left: 10px;
    font-weight: 600;
}
.blog-box .blog-box-footer i {
    margin: 0 5px ;
}

i{
    margin-right: 10px;
    font-size: 20px;
}

.color{
    color: gray;
}

@media only screen and (max-width:800px) 
{

    .pattern1
    {
        margin-top: -110px !important;
    }
    
}

@media only screen and (max-width:990px)
{
    .rightside-footer{
        margin-top: 30px;
    }
    .social-list{
        margin-top: 80px !important;
        margin-left: -250px;
    }
    .logo-footer{
        margin-left: 10px;
    }
    .geniepetcommunity{
        text-align: left !important;
        margin-top: 20px !important;
    }

    .brand-image{
        margin-left: 10px !important;
        margin-bottom: -65px !important;
    }
}

@media only screen and (max-width:435px)
{
    .copyrighttext
    {
      font-size: 14px !important;
    }

}

  @media screen and (max-width:1000px) and (min-width:770px)
  {

    .fa-instagram , .fa-facebook
    {

        font-size: 36px !important;
        margin-right: 30px !important;

    }

    .list-inline-item
    {
        margin-left: 0px !important;
    }

    .social-list
    {
        padding-left: -50px !important;
        padding-right: 0px !important;
        margin-top: 10px !important;
        text-align: left !important;
        margin-left: 0px !important;
    }
      
  }

  @media screen and (max-width:770px) and (min-width:280px){

    
    .fa-instagram , .fa-facebook{

        font-size: 28px !important;
        margin-right: 30px !important;

    }
    .list-inline-item{
        margin-left: 0px !important;
    }

    .social-list{
        padding-left: -50px !important;
        padding-right: 0px !important;
        margin-top: 10px !important;
        text-align: left !important;
        margin-left: 0px !important;
    }

      
  }